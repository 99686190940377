const data = {
    years: 25,
    employee: '4000',
    country: 35,
    branch: 6,
    SITE_KEY: '6LeZ7bchAAAAADW9z0qtJqPzoZ21L91sFTrUW9eC',
    API_URL: 'https://www.smart-camera.vn'
};

export default data;
