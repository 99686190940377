import React from "react";
import "../../../assets/sass/solutions/accessControl/systemSupport.scss";

const SystemSupport = () => {
  const itemSytemSupports = [
    {
      title: "Kết nối đồng bộ thông tin",
      content:
        "Kết nối và đồng bộ thông tin với các hệ thống quản lý hiện có của ngân hàng, hỗ trợ truy xuất thông tin chính xác, thống nhất.",
      icon: require("../../../assets/image/systemSuport/icon_connect.webp"),
    },
    {
      title: "Kiểm tra đối chiếu thông tin",
      content:
        "Ghi nhận thông tin cá nhân của các thành viên có thẩm quyền ra vào kho quỹ và đối chiếu tự động với thông tin trên hệ thống quản lý hiện tại của ngân hàng.",
      icon: require("../../../assets/image/systemSuport/icon_test.webp"),
    },
    {
      title: "Kiểm soát ra vào kho quỹ",
      content:
        "Kiểm soát nhận diện thông tin thành viên kho quỹ trong quá trình mở cửa kho và các trạng thái của kho quỹ trong quá trình thực hiện công việc của ban quản lý kho quỹ.",
      icon: require("../../../assets/image/systemSuport/icon_control.webp"),
    },
    {
      title: "Kiểm soát quá trình mở kho quỹ",
      content:
        "Nhận diện và kiểm soát sự có mặt giám sát đầy đủ của các thành viên ban quản lý trong quá trình thực hiện công việc theo quy định của ngân hàng nhà nước.",
      icon: require("../../../assets/image/systemSuport/icon_control_2.webp"),
    },
    {
      title: "Báo cáo vi phạm",
      content:
        "Theo dõi thống kê báo cáo tình hình thực hiện một cách trực quan, cảnh báo các vi phạm theo quy định của ngân hàng. Kiểm soát chặt chẽ các hành vi.",
      icon: require("../../../assets/image/systemSuport/icon_report.webp"),
    },
    {
      title: "Hỗ trợ",
      content:
        "- Đa ngôn ngữ <br/>- Đào tạo chuyển giao công nghệ </br>- Mở rộng hệ thống <br/> - Giao diện thân thiện và dễ sử dụng",
      icon: require("../../../assets/image/systemSuport/icon_support.webp"),
    },
    {
      title: "Ứng phó sự cố",
      content:
        "Hỗ trợ đầy đủ các giải pháp xử lý sự cố phát sinh trong quá trình vận hành, cơ chế failover, tự động theo dõi trạng thái hoạt động của trang thiết bị, cơ chế kiểm tra báo cáo sự cố,...",
      icon: require("../../../assets/image/systemSuport/icon_cope.webp"),
    },
  ];

  return (
    <section className="section" id="system-support" data-aos="fade-right">
      <div className="systemSupport_bg">
        <div className="container containerDetail">
          <div className="systemSupport ">
            <div className="systemSupport_title">HỆ THỐNG HỖ TRỢ</div>
            <div className="systemSupport_items row">
              {itemSytemSupports.map((itemSytemSupport, index) => {
                return (
                  <div
                    className={`sys-col-box col-xl-4 col-lg-6 col-md-6 col-sm-12 animation-01`}
                    key={index}
                  >
                    <div className="box_item">
                      <div className="bg_item">
                        <img
                          className="bg_item_icon"
                          src={itemSytemSupport.icon}
                          alt="bg_item_icon"
                        />
                        <div className="bg_item_bottom">
                          <div
                            className="bg_item_bottom_title"
                            dangerouslySetInnerHTML={{
                              __html: itemSytemSupport.title,
                            }}
                          ></div>
                          <hr className="bg_item_bottom_hr" />
                          <div
                            className="bg_item_bottom_content"
                            dangerouslySetInnerHTML={{
                              __html: itemSytemSupport.content,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SystemSupport;
