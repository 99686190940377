import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/sass/admin/solutions.scss";
import ButtonSolution from "./button-solution";

function LaborSafety() {
  return (
    <section className="section" style={{ paddingTop: "60px" }}>
      <div className="carousel">
        <div className="d-flex justify-content-between solutions">
          <div className="col-lg-5 solutions-image padding-left-0 padding-right-0">
            <div className="col p-0 card-image">
              <div className="middle">
                <img
                  src={require("../../assets/image/solutions/image7.webp")}
                  alt="img"
                />
              </div>
            </div>
            <div className="col p-0 card-image">
              <div className="middle">
                <img
                  src={require("../../assets/image/solutions/image8.webp")}
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7 solutions-text padding-right-0 padding-left-37">
            {" "}
            <p>Giải pháp kiểm soát an ninh -</p>
            <p className="margin-top--15">an toàn lao động</p>
            <ul className="a">
              <li>
                <span className="material-symbols-outlined icon_title">
                  {" "}
                  trip_origin{" "}
                </span>
                <div className="title_solution">
                  Giải pháp kiểm soát ra vào tòa nhà - văn phòng, nhà máy sản
                  xuất:
                </div>
                <ul className="b margin-left--20">
                  <li className="padding-top-10 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Kiểm tra QR-Code thẻ xanh covid</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Kiểm tra, nhắc nhở đeo khẩu trang</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Chấm công nhân viên bằng FaceID</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Nhận diện và chào đón khách</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Nhận diện người lạ, blacklist</p>
                  </li>
                </ul>
              </li>
              <li className="padding-top-20">
                <span className="material-symbols-outlined icon_title">
                  {" "}
                  trip_origin{" "}
                </span>
                <div className="title_solution">Kiểm tra an toàn lao động</div>
                <ul className="b margin-left--20">
                  <li className="padding-top-10 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Kiểm tra trang bị bảo hộ</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Kiểm tra hành lang an toàn</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Cảnh báo khu vực nguy hiểm Phát hiện</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Phát hiện, cảnh báo cháy nổ</p>
                  </li>
                  <div className="d-flex justify-content-end padding-top-20">
                    <ButtonSolution />
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LaborSafety;
