import React from "react";
import AboutUs from "./aboutUs";
import AppTech from "./appTech";
import Solutions from "./solution";
import SystemSupport from "./systemSupport";
import ContactUs from "../../contactUs";
import "../../../assets/sass/solutions/accessControl/home.scss";

export default function home() {
  return (
    <section>
      <Solutions />
      <AppTech />
      <SystemSupport />
      <AboutUs />
      <ContactUs

        detail_phone="(028) 3995 1061"
        detail_email="contact@tmainnovation.vn"
        detail_address="Toà nhà TMA, #10 Công viên Phần mềm Quang Trung, Phuờng Tân Chánh Hiệp, Quận 12, TP. Hồ Chí Minh"
      />
    </section>
  );
}
