/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../assets/sass/solutions/accessControl/contactUs.scss";
import { Formik, Form, Field } from "formik";
import data from "./config";
import * as Yup from "yup";
import ApiCaller from "../api/apiCaller";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const ContactUs = ({
  hotline_phone,
  hotline_email,
  detail_phone,
  detail_email,
  detail_address,
}) => {
  const [dataContact, setdataContact] = useState({
    fullname: "",
    companyname: "",
    phone: "",
    email: "",
    comment: "",
  });
  const [loading, setLoading] = useState(false);

  const SignupSchema = Yup.object().shape({
    fullname: Yup.string().required("Vui lòng nhập tên liên hệ"),
    companyname: Yup.string().required("Vui lòng nhập tên công ty"),
    phone: Yup.string()
      .required("Vui lòng nhập số điện thoại")
      .matches(/^[0-9]+$/, "Số điện thoại không đúng định dạng")
      .min(10, "Số điện thoại không được ít hơn 10 số")
      .max(11, "Số điện thoại không được quá 11 số"),
    email: Yup.string()
      .email("Email không hợp lệ")
      .required("Vui lòng nhập email"),
    comment: Yup.string(),
  });

  //gg recaptcha
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${data.SITE_KEY}`,
      function () {}
    );
  }, []);

  const handleOnSubmit = async (dataForm, actions) => {
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(data.SITE_KEY, {
          action: "submit",
        })
        .then(async (reCaptchaToken) => {
          if (reCaptchaToken) {
            let reqData = Object.assign({}, dataForm, {
              "g-recaptcha-response": reCaptchaToken,
            });
            const res = await ApiCaller(
              "modules/contact.php",
              "POST",
              reqData
            ).then((res) => {
              setLoading(false);
              if (res.status === 200) {
                Toastify({
                  text: res.data,
                  duration: 2500,
                  gravity: "top",
                  position: "right",
                  backgroundColor: "#28a745",
                  stopOnFocus: true,
                }).showToast();
              } else {
                Toastify({
                  text: "Gửi email không thành công",
                  gravity: "top",
                  position: "right",
                  backgroundColor: "#dc3545",
                  stopOnFocus: true,
                }).showToast();
              }
            });
          }
        });
    });
    actions.resetForm();
  };

  const iconZooms = [
    { id: 1, imgIcon: "bg-icon_zalo", hoverIcon: "zoomInPut1" },
    { id: 2, imgIcon: "bg-icon_facebook", hoverIcon: "zoomInPut1" },
    { id: 3, imgIcon: "bg-icon_in", hoverIcon: "zoomInPut1" },
    { id: 4, imgIcon: "bg-icon_youtube", hoverIcon: "zoomInPut1" },
  ];
  const [zoomIcon, setZoomIcon] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    const TimeOut = setTimeout(() => {
      if (zoomIcon < iconZooms.length) {
        setZoomIcon(zoomIcon + 1);
      } else {
        setZoomIcon(0);
      }
    }, 3000);
    return () => {
      clearTimeout(TimeOut);
    };
  }, [zoomIcon]);

  return (
    <section className="section contact-us" id="contact-us">
      <div className="bg-contact">
        <img
          src={require("../assets/image/solutions/solutionAccessControl/appTech/bg-app-tech.webp")}
          className="bg-contact"
          alt="img"
        />
      </div>
      <div
        className="container containerDetail"
        style={{ position: "relative" }}
      >
        <div className="content-contact">
          <div className="infor-contact">
            {/* <span className="bg-tma_logo"></span> */}
            <img
                alt="TMA_Innovation_Logo_White"
                src={require("../assets/image/contact/TMA_Innovation_Logo_White.webp")}
                width="160px"
                height="auto"
                className="bg-tma_logo"
              />
        
            <div className="detail-infor">
              <h3 className="title-detail-infor">Thông tin liên hệ</h3>
              <div className="phone_detail">
                <span className="bg-icon_phone_white"></span>
                <a
                  href="tel:02839951061"
                  rel="noreferrer"
                  target="_blank"
                  className="text-detail"
                >
                  {detail_phone ? detail_phone : null}
                </a>
              </div>
              <div className="phone_detail">
                <span className="bg-icon_email_white"></span>
                <a
                  href="mailto:contact@tmainnovation.vn"
                  rel="noreferrer"
                  target="_blank"
                  className="text-detail"
                >
                  {" "}
                  {detail_email ? detail_email : null}
                </a>
              </div>
              <div className="phone_detail">
                <span className="bg-icon_address_white"></span>
                <p className="text-detail">
                  {detail_address ? detail_address : null}
                </p>
              </div>
            </div>
            <hr style={{ background: "white" }} />
            <div className="another-contact">
              {iconZooms.map((iconZooms, index) => {
                return (
                  <span
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    key={iconZooms.id}
                    className={`${iconZooms.imgIcon} ${iconZooms.hoverIcon} ${
                      zoomIcon === index && isHovered === false
                        ? "zoomInPut"
                        : ""
                    }`}
                  ></span>
                );
              })}
            </div>
          </div>
          <div className="form-contact">
            <div className="form-content">
              <p className="title-form">Đăng Ký Tư Vấn</p>
              <Formik
                initialValues={dataContact}
                validationSchema={SignupSchema}
                onSubmit={handleOnSubmit}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors }) => (
                  <Form autoComplete="off">
                    <div className="form-input">
                      <Field
                        required
                        id="fullname"
                        name="fullname"
                        type="text"
                        className="form-control"
                      />
                      <label htmlFor="fullname" className="label-form">
                        Tên người liên hệ{" "}
                      </label>
                    </div>
                    {errors.fullname ? (
                      <p className="error-form">{errors.fullname}</p>
                    ) : null}

                    <div className="form-input">
                      <Field
                        id="companyname"
                        name="companyname"
                        type="text"
                        className="form-control"
                        required
                      />
                      <label htmlFor="companyname" className="label-form">
                        Tên công ty{" "}
                      </label>
                    </div>
                    {errors.companyname ? (
                      <p className="error-form">{errors.companyname}</p>
                    ) : null}

                    <div className="form-input">
                      <Field
                        id="phone"
                        name="phone"
                        type="text"
                        className="form-control"
                        required
                      />
                      <label htmlFor="phone" className="label-form">
                        Số điện thoại{" "}
                      </label>
                    </div>
                    {errors.phone ? (
                      <p className="error-form">{errors.phone}</p>
                    ) : null}

                    <div className="form-input">
                      <Field
                        id="email"
                        name="email"
                        type="text"
                        className="form-control"
                        required
                      />
                      <label htmlFor="email" className="label-form">
                        Địa chỉ email{" "}
                      </label>
                    </div>
                    {errors.email ? (
                      <p className="error-form">{errors.email}</p>
                    ) : null}

                    <div className="form-input">
                      <Field
                        id="comment"
                        name="comment"
                        type="text"
                        className="form-control"
                        placeholder="Đặt vấn đề với chúng tôi"
                      />
                    </div>

                    <button
                      type="submit"
                      className="submit-button"
                      disabled={loading}
                    >
                      {loading === true ? (
                        <div className="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        " Đăng ký"
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactUs;
