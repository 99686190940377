import React from "react";
import "../assets/sass/admin/solutionCore.scss";
import CoreSolutions from "./CoreSolutions";
import Benefit from "./Benefit";
import About from "./About";
import TitleGallaries from "./titleGallaries";
import Gallaries from "./Gallaries";
import Solutions from "./solutions/index";
import ContactUs from "./contactUs";

export default function Home() {
  return (
    <section>
      <CoreSolutions />
      <Solutions />
      <Benefit />
      <About />
      <TitleGallaries />
      <Gallaries />
      <ContactUs
        detail_phone="(028) 3995 1061"
        detail_email="contact@tmainnovation.vn"
        detail_address="Toà nhà TMA, #10 Công viên Phần mềm Quang Trung, Phuờng Tân Chánh Hiệp, Quận 12, TP. Hồ Chí Minh"
      />
    </section>
  );
}
