import React, { useEffect, useState } from "react";
import "../../../assets/sass/solutions/accessControl/solution.scss";
import "../../../assets/sass/solutions/accessControl/home.scss";

const Solutions = () => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const list = document.querySelectorAll("img.images");
    setTimeout(() => {
      if (active < list.length) {
        setActive(active + 1);
      } else {
        setActive(0);
      }
    }, 2500);
    for (let i = 0; i < list.length; i++) {
      if (active === i) {
        list[i].classList.add("animation-01");
      } else {
        list[i].classList.remove("animation-01");
      }
    }
  }, [active]);

  return (
    <section className="section  solution" id="solution" data-aos="fade-right">
      <div className="container containerDetail" style={{ height: "100%" }}>
        <h3 className="title-solution">GIẢI PHÁP</h3>
        <div className="content-solution">
          <h4 className="title-solution-2">
            XÂY DỰNG HỆ THỐNG
            <br /> QUẢN LÝ TOÀN DIỆN
          </h4>

          <div className="solution-scan animation">
            <p className="content-text">
              Hệ thống nhận diện <br />
              thành viên BQL kho quỹ
            </p>
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/img-scan.webp")}
              alt="img-scan"
              className="images img-scan"
            />
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/arrow_1.webp")}
              alt="img-arrow_1"
              className="arrow_1"
            />
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/arrow_2.webp")}
              alt="img-arrow_2"
              className="arrow_2"
            />
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/arrow_3.webp")}
              alt="img-arrow_3"
              className="arrow_3"
            />
          </div>
          <div className="solution-camera animation">
            <p className="text-camera content-text">
              Hệ thống camera thông minh giám
              <br /> sát quá trình cửa kho quỹ mở
            </p>
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/img-camera.webp")}
              alt="img-camera"
              className="images img-camera"
            />
          </div>
          <div className="solution-managementSystem animation">
            <p className="content-text">
              Hệ thống quản lý thống kê <br /> báo cáo tại hội sở
            </p>
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/img-managementSystem.webp")}
              alt="img-managementSystem"
              className="images img-managementSystem"
            />
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/arrow_4.webp")}
              alt="img-arrow_4"
              className="arrow_4"
            />
          </div>
          <div className="solution-lock animation">
            <p className="content-text">
              Hệ thống khóa <br /> thông minh
            </p>
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/img-lock.webp")}
              alt="img-lock"
              className="images img-lock"
            />
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/arrow_5.webp")}
              alt="img-arrow_5"
              className="arrow_5"
            />
          </div>
          <div className="solution-vault animation">
            <p className="content-text">Kho quỹ ngân hàng</p>
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/img-vault.webp")}
              alt="img-vault"
              className="images img-vault"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Solutions;
