import React from "react";
import "../../../assets/sass/solutions/accessControl/home.scss";
import "../../../assets/sass/solutions/accessControl/appTech.scss";
import icon_app_01 from "../../../assets/image/solutions/solutionAccessControl/appTech/icon-app-01.webp";
import icon_app_02 from "../../../assets/image/solutions/solutionAccessControl/appTech/icon-app-02.webp";
import icon_app_03 from "../../../assets/image/solutions/solutionAccessControl/appTech/icon-app-03.webp";
const AppTech = () => {
  const dataApp = [
    {
      id: 1,
      title: "Artificial Intelligence",
      icon: icon_app_01,
      text: "Công nghệ AI hỗ trợ nhận diện thành viên BQL kho quỹ, chống giả mạo và cảnh báo người lạ xâm nhập.",
    },
    {
      id: 2,
      title: "Internet of Things",
      icon: icon_app_02,
      text: "Hệ thống IoT cho phép quản lý tập trung thiết bị, điều khiển hệ thống từ xa, xử lý nhanh sự cố.",
    },
    {
      id: 3,
      title: "Management System",
      icon: icon_app_03,
      text: "Tất cả các hoạt động được cập nhật theo thời gian thực, giúp hội sở quản lý và các chi nhánh có thể tối ưu hóa thời gian trao đổi thông tin. Bên cạnh đó, các giải pháp đảm bảo an ninh được tích hợp giúp đảm bảo an ninh, an toàn của đơn vị.",
    },
  ];
  return (
    <section className="section app-tech" id="app-tech" data-aos="fade-right">
      <h3 className="title-app">ỨNG DỤNG CÁC CÔNG NGHỆ</h3>
      <div className="content-app ">
        <div className="container containerDetail">
          <div className="content-top row">
            {dataApp &&
              dataApp.map((item) => {
                return (
                  <div
                    className="box-content col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12"
                    key={item.id}
                  >
                    <div className="title-box">
                      <img
                        src={item.icon}
                        alt="icon-app"
                        className="icon-app"
                      />
                      <p>{item.title}</p>
                    </div>
                    <div className="box-text">
                      <p>{item.text}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="content-bottom">
          <img
            src={require("../../../assets/image/solutions/solutionAccessControl/appTech/bg-app-tech.webp")}
            alt="bg-app-tech"
            className="bg-appTech"
          />
          <div className="container containerDetail">
            <div className="app-target">
              <div className="content-target">
                <div className="img-target">
                  <img
                    src={require("../../../assets/image/solutions/solutionAccessControl/appTech/circle-app-01.webp")}
                    alt="circle-app"
                    className="circle-app"
                  />
                  <img
                    src={require("../../../assets/image/solutions/solutionAccessControl/appTech/circle-app-rolling.webp")}
                    alt="circle-app"
                    className="circle-app-rolling"
                  />
                  <img
                    src={require("../../../assets/image/solutions/solutionAccessControl/appTech/circle-app-mobile-01.webp")}
                    alt="circle-app"
                    className="circle-app-mobile"
                  />
                </div>
                <div className="box-content-target">
                  <div className="box box-1">
                    <img
                      src={require("../../../assets/image/solutions/solutionAccessControl/appTech/icon-app-04.webp")}
                      alt="icon-app"
                      className="icon-app-target"
                    />
                    <p className="text-box">
                      Hạn chế tối đa các rủi ro trong quy trình quản lý
                    </p>
                  </div>
                  <div className="box box-2">
                    <img
                      src={require("../../../assets/image/solutions/solutionAccessControl/appTech/icon-app-05.webp")}
                      alt="icon-app"
                      className="icon-app-target"
                    />
                    <p className="text-box">
                      Giám sát chặt chẽ việc thực hiện quy định
                    </p>
                  </div>
                  <div className="box box-3">
                    <img
                      src={require("../../../assets/image/solutions/solutionAccessControl/appTech/icon-app-06.webp")}
                      alt="icon-app"
                      className="icon-app-target"
                    />
                    <p className="text-box">Tăng cường an ninh an toàn</p>
                  </div>
                </div>
              </div>
              <div className="content-bot">
                <p className="text-box-bot">
                  Ứng dụng các công nghệ hiện đại, hỗ trợ kiểm soát quy trình mở
                  cửa kho quỹ theo quy định của ngân hàng nhà nước và{" "}
                  <span>
                    phòng ngừa các hành vi lợi dụng sơ hở của cấp quản lý để đột
                    nhập và đánh cắp tiền trong kho quỹ ngân hàng
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AppTech;
