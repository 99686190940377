import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/sass/admin/solutions.scss";
import ButtonSolution from "./button-solution";

function SmartRetail() {
  return (
    <section className="section" style={{ paddingTop: "0px" }}>
      <div className="carousel">
        <div className="d-flex justify-content-between solutions">
          <div className="col-5 solutions-image padding-left-0 ">
            {/* <div>
                        {offices.length > 0 ? (
                            <Slider {...settings}>
                            {offices.map((photo) => {
                                return (
                                <div className="card-image">
                                    <div className="middle">
                                    <img src={photo} />
                                    </div>
                                </div>
                                );
                            })}
                            </Slider>
                        ) : (
                            <div></div>
                        )}
                    </div> */}
            <div style={{ with: "100%" }}>
              <img
                src={require("../../assets/image/solutions/image_retail.webp")}
                height="268px"
                width="100%"
                alt="img"
              />
            </div>
            <div
              className="d-flex"
              style={{ with: "100%", paddingTop: "22px" }}
            >
              <div
                className="col-6"
                style={{ paddingLeft: "0px", paddingRight: "8px" }}
              >
                <img
                  src={require("../../assets/image/solutions/image2.webp")}
                  className="image_top"
                  alt="img"
                />
              </div>
              <div
                className="col-6"
                style={{ paddingRight: "0px", paddingLeft: "8px" }}
              >
                <img
                  src={require("../../assets/image/solutions/image1.webp")}
                  height="177px"
                  width="100%"
                  alt="img"
                />
              </div>
            </div>
            <div style={{ with: "100%", paddingTop: "22px" }}>
              <img
                src={require("../../assets/image/solutions/image4.webp")}
                className="image4_retail"
                alt="img"
              />
            </div>
            <div
              className="d-flex"
              style={{ with: "100%", paddingTop: "22px" }}
            >
              <div className="col-6" style={{ paddingLeft: "0px" }}>
                <img
                  src={require("../../assets/image/solutions/image3.webp")}
                  className="image_bot"
                  alt="img"
                />
              </div>
              <div
                className="col-6"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <img
                  src={require("../../assets/image/solutions/image6.webp")}
                  className="image_bot"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="col-7 solutions-text padding-right-0">
            {" "}
            <p>Smart Retail</p>
            <div>
              <span>
                {" "}
                Giải pháp Smart Retail được xây dựng trên nền tảng công nghệ 4.0
                với sự kết hợp giữa AI{" "}
              </span>
              <span>
                {" "}
                và hệ thống IoT, giúp các doanh nghiệp thu thập, phân tích thông
                tin khách hàng một{" "}
              </span>
              <span>
                {" "}
                cách chủ động hơn, hỗ trợ tạo nên cái nhìn từ tổng quát đến chi
                tiết về khách hàng và tình hình{" "}
              </span>
              <span>
                {" "}
                kinh doanh của doanh nghiệp trong ngành bán lẻ. Bộ giải pháp
                Smart Retail bao gồm{" "}
              </span>
              <span> các ứng dụng, hỗ trợ: </span>
            </div>
            <ul className="a">
              <li>
                <span className="material-symbols-outlined icon_title">
                  trip_origin
                </span>
                <div className="title_solution">
                  Xây dựng cơ sở dữ liệu khách hàng tập trung, thống nhất
                </div>
                <ul className="b margin-left--25">
                  <li className="padding-top-10 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      Hệ thống Smart camera nhận diện khuôn mặt và thiết bị
                      thông minh giúp nhận diện, và thu thập thông tin khách
                      hàng.
                    </p>
                  </li>
                  <li className="padding-top-5 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      {" "}
                      Ứng dụng trên thiết bị di dộng cung cấp các thông tin
                      khách hàng và hỗ trợ đội ngũ kinh doanh ghi nhận các dữ
                      liệu về khách hàng dễ dang hơn.
                    </p>
                  </li>
                  <li className="padding-top-5 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      {" "}
                      Tự động hóa các thao tác như cập nhật đơn giá hằng ngày,
                      trích xuất thông tin trên giấy tờ tùy thân của khách
                      hàng,…
                    </p>
                  </li>
                  <li className="padding-top-5 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      {" "}
                      Nâng cao khả năng mô phỏng tình hình thực tế với sự kết
                      hợp giữa camera AI và hệ thống IoT
                    </p>
                  </li>
                </ul>
              </li>
              <li className="padding-top-20">
                <span className="material-symbols-outlined icon_title">
                  trip_origin
                </span>
                <div className="title_solution">
                  Phân tích, đánh giá hiệu quả kinh doanh
                </div>
                <ul className="b margin-left--25">
                  <li className="padding-top-10 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      {" "}
                      Thống kê lưu lượng khách ra vào kết hợp các hệ thống thanh
                      toán, lượt khách thanh toán, cung cấp các chỉ số về hoạt
                      động kinh doanh, hiệu quả các chiến dịch sale-marketing.
                    </p>
                  </li>
                  <li className="padding-top-5 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      {" "}
                      Self-service box đo lượng chất lượng phục vụ, dịch vụ. Hỗ
                      trợ quảng cáo thông tin sản phẩm, các chiến dịch sale-
                      marketing thu hút hơn.
                    </p>
                  </li>
                </ul>
              </li>
              <li className="padding-top-20">
                <span className="material-symbols-outlined icon_title">
                  trip_origin
                </span>
                <div className="title_solution">
                  Thống kê, phân tích hành vi khách hàng
                </div>
                <ul className="b margin-left--25">
                  <li className="padding-top-10 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      Đo lượng đánh giá mức độ quan tâm đến sản phẩm, quảng cáo.
                    </p>
                  </li>
                  <li className="padding-top-5 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      Nhận diện độ tuổi, giới tính, lưu lượng khách, hỗ trợ định
                      vị chính xác các tập khách hàng mang đến doanh thu.
                    </p>
                  </li>
                  <li className="padding-top-5 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      Phân tích các hành vi mua hàng, đánh giá nhu cầu thị
                      trường thông qua các biểu đồ báo các thống kê, heatmap,…
                    </p>
                  </li>
                  <li className="padding-top-5 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      Kết hợp AI, Data Science, Big data analytics phân tích các
                      dữ liệu lịch sử, tìm kiếm các insight khách hàng một các
                      tự động. Hướng đến tối ưu hóa các hoạt động, chiến lược
                      kinh doanh của doanh nghiệp trong ngành bán lẻ.
                    </p>
                  </li>
                  <span className="text-solution-nav">
                    Giải pháp Smart Retail mong muốn hỗ trợ các doanh nghiệp bán
                    lẻ nâng cao chất lượng phục vụ, dịch vụ, hiệu quả kinh doanh
                    cho doanh nghiệp, tạo ra nguồn dữ liệu phục vụ phát triển
                    kinh doanh, sản phẩm, dịch vụ mới, hướng đến giảm chi phí
                    vận hành và tăng thêm các nguồn doanh thu mới.
                  </span>
                  <div className="d-flex justify-content-end padding-top-20">
                    <ButtonSolution link="https://www.smartstore.com.vn" />
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmartRetail;
