import React from "react";
import "../assets/sass/admin/about_top.scss";
import data from "../components/config";

export default function Benefit() {
  const icon = (
    <span className="material-symbols-outlined icon_title_benefit">
      {" "}
      trip_origin{" "}
    </span>
  );
  return (
    <section className="section" id="about-us" style={{ paddingTop: "100px" }}>
      <div className="flex mb-5">
        <div className="title-block mb-3" style={{ marginBottom: "-30px" }}>
          Về chúng tôi
        </div>
        <span className="bg-line"></span>
      </div>
      <div className="container" style={{ paddingBottom: "67px" }}>
        <div className="d-flex pb-4 justify-content-around">
          <div className="container_box_about">
            <div className="box_left">
              <img
                src={require("../assets/image/Benefit_about/logo_left.webp")}
                className="logo_benefit"
                alt="img"
              />
              <ul className="box">
                <li>
                  {icon}
                  <p>
                    <span>{data.years}</span> năm dẫn đầu thị trường phát triển
                    phần mềm
                  </p>
                </li>
                <li>
                  {icon}{" "}
                  <p>
                    Khách hàng <span>{data.country}</span> nước
                  </p>
                </li>
                <li>
                  {icon}
                  <p>
                    <span>{data.employee}</span> kỹ sư{" "}
                  </p>
                </li>
                <li>
                  {icon}
                  <p>
                    <span>Top 10</span> doanh nghiệp cung cấp giải pháp 4.0
                  </p>
                </li>
                <li>
                  {icon}
                  <p>
                    <span>Top 10</span> doanh nghiệp AI & IoT
                  </p>
                </li>
                <li>
                  {icon}
                  <p>
                    <span>Top 10</span> doanh nghiệp Fintech
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <img
                alt="img"
                src={require("../assets/image/Benefit_about/backgroud_mid_abouts.webp")}
              />
            </div>
            <div className="box_left box_right">
              <img
                src={require("../assets/image/Benefit_about/logo_right.webp")}
                className="logo_benefit"
                alt="img"
              />
              <ul className="box">
                <li>
                  {icon}
                  <p>Tiên phong ứng dụng giải pháp tiên tiến</p>
                </li>
                <li>
                  {icon}
                  <p>Đội ngũ lãnh đạo, chuyên gia đầu ngành robotics</p>
                </li>
                <li>
                  {icon}
                  <p>Mạng lưới phân phối lớn</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="d-flex justify-content-around about"
          style={{ padding: "86px 116px 116px 82px" }}
        >
          <div className="col-md-5 pt-1 centerImage">
            <div className="middle" style={{ borderRadius: "18px" }}>
              <img
                width="100%"
                src={require("../assets/image/gallaries/IMG_5845.webp")}
                alt="img"
              />
            </div>
          </div>
          <div className="col-md-6 padding-left-0 padding-right-0">
            <p id="about-title">
              TMA INNOVATION VÀ THADOSOFT KÝ KẾ HỢP TÁC CHIẾN LƯỢC
            </p>
            {/* <p id="about-title" style={{ fontWeight: "bold", fontSize: "22px", lineHeight: "48px",marginTop: "-20px"}}>LƯỢC</p> */}
            <p id="about-text">
              Tháng 9 năm 2020, ông Trần Phúc Hồng - Giám đốc TMA Innovation và
              Ông Phạm Xuân Sang - Giám đốc Thadosoft đã ký kết và công bố quan
              hệ hợp tác chiến lược về phát triển và triển khai các giải pháp
              công nghệ 4.0 tại thị trường Việt Nam bao gồm ứng dụng camera
              thông minh đa lĩnh vực, đo thân nhiệt tự động, IoTs quản lý và
              theo dõi,…
            </p>
            <p id="about-text">
              Với sứ mệnh phổ dụng công nghệ 4.0, TMA Innovation sẽ tập trung
              vào công nghệ và phát triển sản phẩm; Thadosoft – thông qua mạng
              lưới phân phối trong cả nước, sẽ tập trung vào tư vấn và triển
              khai cho các doanh nghiệp, giúp các doanh nghiệp ứng dụng công
              nghệ mới để nâng cao năng lực cạnh tranh với hiệu quả cao và chi
              phí thấp.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
