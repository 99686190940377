import React from "react";
import { Row, Col } from "react-bootstrap";
import "../../assets/sass/admin/solutions.scss";

import ButtonSolution from "./button-solution";

function AccessControl() {
  return (
    <div style={{ paddingTop: "60px" }}>
      <Row>
        <Col lg={8} md={12} sm={12}>
          <div className="access-control-title">
            <span>Giải pháp Access Control</span>
          </div>
          <div className="access-control-msg">
            <span className="pt-5">
              Hỗ trợ kiểm soát ra vào khu vực hạn chế, kho quỹ ngân hàng
            </span>
          </div>
          <div className="padding-top-20">
            <img
              alt="img"
              src={require("../../assets/image/solutions/access-control.webp")}
              className="access-control-img"
            />
          </div>
          <div className="access-control-btn padding-top-20">
            <ButtonSolution link="/accesscontrol" />
          </div>
        </Col>
        <Col lg={4} md={12} sm={12}>
          <div>
            <img
              alt="img"
              className="img-icon"
              src={require("../../assets/image/solutions/icon-bank.webp")}
            />
          </div>
          <div className="padding-top-20">
            <Row>
              <Col>
                <div className="padding-rigth-17">
                  <img
                    alt="img"
                    src={require("../../assets/image/icon-t-cam-new.webp")}
                    className="icon-t-cam-new-Asscess"
                  />
                </div>
              </Col>
              <Col>
                <div>
                  <img
                    alt="img"
                    className="img-icon"
                    src={require("../../assets/image/solutions/icon-smatphone.webp")}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="padding-top-25">
            <div>
              <img
                alt="img"
                className="img-icon"
                src={require("../../assets/image/solutions/img-laboratory.webp")}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AccessControl;
