import React from "react";
import { Row } from "react-bootstrap";
// import useIntersectionObserver from "@react-hook/intersection-observer";
import video_banner from "../assets/image/img_video_banner.webp";
const Banner = () => {
  // const containerRef = useRef();
  // const lockRef = useRef(false);
  // const { isIntersecting } = useIntersectionObserver(containerRef);
  // console.log(isIntersecting);
  // if (isIntersecting) {
  //   lockRef.current = true;
  // }
  return (
    <Row>
      <div className="banner-layer col-lg-6 col-md-6">
        <div className="video-wrapper">
          <iframe
            width="100%"
            height="278px"
            src="https://www.youtube.com/embed/fTsxUW92Nlg?autoplay=1&mute=1"
            frameBorder="0"
            allow="picture-in-picture"
            allowFullScreen
            loading="lazy"
            title="video"
            srcDoc={`
            <a href='https://www.youtube.com/embed/fTsxUW92Nlg?autoplay=1&mute=1'>
              <img src=${video_banner} alt='Coffee Recipe Javascript Project'>
            </a>
             `}
          ></iframe>
        </div>
      </div>

      <div className="d-flex justify-content-center col-lg-6 col-md-6 padding-left-0 padding-top-44 margin-left--8 ">
        <div className="tet-xcenter text-md-left pl-0 pl-md-3 padding-left-0">
          <div className="mt-3 mt-md-0 mb-4">
            <div id="camera-title">Giải pháp AI cho Camera </div>
            <div className="camera-text">
              Nâng cấp hệ thống camera cho doanh nghiệp, giúp mở rộng khả năng
              quan sát, phân tích, phát hiện và cảnh báo.
            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default Banner;
