import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/sass/admin/gallaries.scss";
import IMG_5848 from "../assets/image/gallaries/IMG_5848.webp";
import IMG_5854 from "../assets/image/gallaries/IMG_5854.webp";
import IMG_5845 from "../assets/image/gallaries/IMG_5845.webp";

const photos = [IMG_5848, IMG_5854, IMG_5845];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  cssEase: "linear",
};

function Gallaries() {
  return (
    <section
      className="section background-diff"
      style={{ background: "#EEF7FC" }}
    >
      <div
        className="gallaries-img d-flex flex-column justify-content-center"
        style={{ paddingBottom: "100px" }}
      >
        <div className="gallaries" style={{ padding: "70px 116px" }}>
          <Slider {...settings}>
            {photos.map((photo, index) => {
              return (
                <div className="card-image" key={index}>
                  <img src={photo} alt="img" />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Gallaries;
