import React from "react";

import "../assets/sass/admin/benefit.scss";

export default function Benefit() {
  // const data = [
  //   {
  //     id: 1,
  //     title: "Giảm chi phí rủi ro",
  //     img: require("../../assets/image/Benefit_about/img_1.webp"),
  //   },
  //   {
  //     id: 2,
  //     title: "Đảm bảo an ninh, an toàn",
  //     img:require( "../../assets/image/Benefit_about/img_2.webp"),
  //   },
  //   {
  //     id: 3,
  //     title: "Giảm chi phí nhân lực",
  //     img: require("../../assets/image/Benefit_about/img_3.webp"),
  //   },
  //   {
  //     id: 4,
  //     title: "Hoạt động 24/7",
  //     img: require("../../assets/image/Benefit_about/img_4.webp"),
  //   },
  // ];
  return (
    <section className="section" style={{ paddingTop: "120px" }}>
      <div>
        <div className="flex" style={{ paddingBottom: "60px" }}>
          <div className="title-block mb-3">HIỆU QUẢ MANG LẠI</div>
          <span className="bg-line"></span>
        </div>
        <div className="cotnainer_benefit">
          <div className="form_fit">
            <div className="form_benefit left">
              <div className="benefit">
                <img
                  alt="img"
                  src={require("../assets/image/Benefit_about/img_1.webp")}
                />
                <div className="content_benefit">
                  <p>Giảm chi phí rủi ro</p>
                </div>
              </div>
              <div className="benefit">
                <img
                  alt="img"
                  src={require("../assets/image/Benefit_about/img_2.webp")}
                />
                <div className="content_benefit">Đảm bảo an ninh, an toàn</div>
              </div>
            </div>
            <div className="form_benefit right">
              <div className="benefit">
                <img
                  alt="img"
                  src={require("../assets/image/Benefit_about/img_3.webp")}
                />
                <div className="content_benefit">Giảm chi phí nhân lực</div>
              </div>
              <div className="benefit">
                <img
                  alt="img"
                  src={require("../assets/image/Benefit_about/img_4.webp")}
                />
                <div className="content_benefit">Hoạt động 24/7</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
