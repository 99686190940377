import React, { useState } from "react";
import { Link } from "react-scroll";
import Banner from "./Banner";
import BannerVault from "./solutions/detailAccessControl/banner";
import "../assets/sass/solutions/accessControl/banner.scss";
import bg_banner from "../assets/image/solutions/solutionAccessControl/banner/bg-banner.webp";
import banner1 from "../assets/image/banner1.webp";
const menu = [
  {
    id: 1,
    href: "home",
    label: "Trang chủ",
  },
  {
    id: 2,
    href: "solution",
    label: "Giải pháp",
  },
  // {
  //   id: 3,
  //   href: "application",
  //   label: "Ứng dụng",
  // },
  {
    id: 4,
    href: "about-us",
    label: "Về chúng tôi",
  },
  {
    id: 5,
    href: "contact-us",
    label: "Liên hệ",
  },
];

const menuAccess = [
  {
    id: 1,
    href: "home",
    label: "Trang chủ",
  },
  {
    id: 2,
    href: "solution",
    label: "Giải pháp",
  },
  {
    id: 3,
    href: "app-tech",
    label: "Công nghệ",
  },
  {
    id: 4,
    href: "about-us",
    label: "News",
  },
  {
    id: 5,
    href: "contact-us",
    label: "Liên hệ",
  },
];

const Logo = () => (
  <div className="navbar-header logo ">
    <div className="navbar-brand">
      <a className="mr-4 image-active first-logo" href="/">
        <img
          src={require("../assets/image/tma-none-active-1.webp")}
          height={"55px"}
          alt="logo"
        />
      </a>
    </div>
  </div>
);

const RenderMenu = (props) => {
  const pathname = window.location.pathname;

  return pathname && pathname === "/accesscontrol"
    ? menuAccess.map((item) => {
        return (
          <li key={item.id}>
            <div>
              <Link
                to={`${item.href}`}
                spy={false}
                smooth={true}
                hashSpy={false}
                duration={1500}
                className={item.active ? "active " : ""}
                data-to-scrollspy-id={item.href}
                // onClick={(e) => handleNavigate(e, item)}
              >
                {item.label}
              </Link>
            </div>
          </li>
        );
      })
    : pathname && pathname === "/"
    ? menu.map((item) => {
        return (
          <li key={item.id}>
            <div>
              <Link
                to={`${item.href}`}
                spy={false}
                smooth={true}
                hashSpy={false}
                duration={1500}
                className={item.active ? "active " : ""}
                data-to-scrollspy-id={item.href}
                // onClick={(e) => handleNavigate(e, item)}
              >
                {item.label}
              </Link>
            </div>
          </li>
        );
      })
    : null;
};

const MenuMobile = (props) => {
  const pathname = window.location.pathname;

  const handleToggleBar = () => {
    props.setShowNav(!props.showNav);
  };

  const navigateHandle = () => {
    props.setShowNav(false);
  };

  return (
    <div className="nav">
      <div className="burger" onClick={handleToggleBar}>
        <span
          className={`line  line1 ${
            props.showNav ? `line1_transform line_transform` : ""
          }`}
        ></span>
        <span
          className={`line line2 ${props.showNav ? "line2_transform" : ""}`}
        ></span>
        <span
          className={`line line3 ${
            props.showNav ? `line3_transform line_transform` : ""
          }`}
        ></span>
      </div>

      {/* menu */}
      <div className={`${props.showNav ? "nav_show" : ""} nav_mobile`}>
        {pathname === "/accesscontrol" ? (
          <ul>
            {menuAccess.map((item) => {
              return (
                <li key={item.id}>
                  <Link
                    to={`${item.href}`}
                    spy={false}
                    smooth={true}
                    hashSpy={false}
                    duration={1500}
                    className={"new-tag-a"}
                    data-to-scrollspy-id={item.href}
                    onClick={navigateHandle}
                    // onClick={(e) => handleNavigate(e, item)}
                  >
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : (
          <ul>
            {menu.map((item) => {
              return (
                <li key={item.id} className={`nav-item`}>
                  <Link
                    to={`${item.href}`}
                    spy={false}
                    smooth={true}
                    hashSpy={false}
                    duration={1500}
                    className={"new-tag-a"}
                    data-to-scrollspy-id={item.href}
                    onClick={navigateHandle}
                    // onClick={(e) => handleNavigate(e, item)}
                  >
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

const Header = (props) => {
  const pathname = window.location.pathname;
  const [showNav, setShowNav] = useState(false);

  return (
    <header
      className={
        pathname && pathname === "/accesscontrol"
          ? "header-front-page bg-banner"
          : "header-front-page"
      }
      style={
        pathname && pathname === "/accesscontrol"
          ? {
              background: `url(${bg_banner})`,
            }
          : { background: `url(${banner1})` }
      }
      id="home"
    >
      <nav role="navigation" id="header">
        <div className="flex-header">
          <div
            className={`height65 ${
              pathname === "/accesscontrol"
                ? "containerDetail container containerDetail_mobile"
                : "container"
            }`}
          >
            <div className="content-header">
              <Logo />
              <div
                id="mainMenu"
                className="top_menu collapse padding-top-20"
                aria-expanded="false"
              >
                <ul className="nav navbar-nav">
                  <RenderMenu menu={menu} />
                </ul>
              </div>
              <div id="mainMenu" className="menu_mobile">
                <MenuMobile setShowNav={setShowNav} showNav={showNav} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            pathname && pathname === "/accesscontrol"
              ? "container h-100 containerDetail"
              : "container h-100"
          }
        >
          <div className="cls-banner-content align-items-stretch home">
            {pathname && pathname === "/accesscontrol" ? (
              <BannerVault />
            ) : (
              <Banner />
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
