import React from "react";

const ButtonSolution = ({ link }) => {
  return (
    <div className="btn_form">
      <a
        className="d-flex btn-details"
        style={{ textDecoration: "none", justifyContent: "center" }}
        href={link}
        target={link === "/accesscontrol" ? "" : "_blank"}
        rel="noreferrer"
      >
        <span
          className="btn-text"
          style={{ textAlign: "center", fontWeight: "500" }}
        >
          Xem chi tiết
        </span>

        <span className="material-symbols-outlined icon_expand icon_form">
          expand_circle_down
        </span>
      </a>
    </div>
  );
};

export default ButtonSolution;
