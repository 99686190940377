import React from "react";
import "../../../assets/sass/solutions/accessControl/aboutUs.scss";
import data from "../../config";
import bg_box from "../../../assets/image/solutions/solutionAccessControl/aboutUs/bg-box.webp";
import box_innovation from "../../../assets/image/solutions/solutionAccessControl/aboutUs/box-innovation.webp";
const AboutUS = () => {
  return (
    <section className="section about-us" id="about-us" data-aos="fade-right">
      <div className="container containerDetail">
        <h3 className="title-aboutUs">VỀ CHÚNG TÔI</h3>
        <div className="about-tma-solution">
          <div className="img-tma-solution">
            <div className="circle"></div>
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/circle-logo-tma-solutoin.webp")}
              alt="circle-tma-solution"
              className="logo-tma-solution"
            />
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/circle-tma-solution-01.webp")}
              alt="circle-tma-solution"
              className="circle-tma-solution"
            />
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/circle-tma-solution-mobile-01.webp")}
              alt="circle-tma-solution"
              className="circle-tma-solution-mobile"
            />
            <h3 className="title-tma-solution">Tập đoàn công nghệ TMA</h3>
          </div>
          <div className="box-tma-solution">
            <div
              className="box"
              style={{
                backgroundImage: `url(${bg_box})`,
              }}
            >
              <img
                src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/icon-about-01.webp")}
                alt="icon-app"
                className="icon-about"
              />
              <p className="text-box">
                <span>{data.years}</span> năm phát triển
              </p>
            </div>
            <div
              className="box"
              style={{
                backgroundImage: `url(${bg_box})`,
              }}
            >
              <img
                src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/icon-about-02.webp")}
                alt="icon-app"
                className="icon-about"
              />
              <p className="text-box">
                <span>{data.employee}</span> kỹ sư
              </p>
            </div>
            <div
              className="box"
              style={{
                backgroundImage: `url(${bg_box})`,
              }}
            >
              <img
                src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/icon-about-03.webp")}
                alt="icon-app"
                className="icon-about"
              />
              <p className="text-box">
                <span>{data.country}</span> quốc gia khách hàng
              </p>
            </div>
            <div
              className="box"
              style={{
                backgroundImage: `url(${bg_box})`,
              }}
            >
              <img
                src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/icon-about-04.webp")}
                alt="icon-app"
                className="icon-about"
              />
              <p className="text-box">
                <span>{data.branch}</span> chi nhánh tại các quốc gia
              </p>
            </div>
          </div>
        </div>
        <div className="about-tma-innovation">
          <div className="box-tma-innovation">
            <div
              className="box-inno"
              style={{
                backgroundImage: `url(${box_innovation})`,
              }}
            >
              <h3 className="title-box-inno">Top 10 công nghệ 4.0</h3>
              <p className="text-box-inno">
                Với hơn 10 năm đầu tư mạnh mẽ vào các công nghệ 4.0, TMA
                Innovation đã và đang cung cấp cho khách hàng nhiều nước những
                sản phẩm sáng tạo ứng dụng các công nghệ mới như: Khoa học dữ
                liệu (Data Science), Trí tuệ nhân tạo (Artificial Intelligence –
                AI), Dữ liệu lớn (Big Data), IoT, Blockchain…và được đánh giá là
                Top 10 công ty có năng lực công nghệ 4.0 hàng đầu Việt Nam.
              </p>
            </div>
            <div
              className="box-inno"
              style={{
                backgroundImage: `url(${box_innovation})`,
              }}
            >
              <h3 className="title-box-inno">Đội ngũ kỹ sư tài năng</h3>
              <ul className="text-box-inno">
                <li>
                  Với hàng trăm kỹ sư nhiều năm kinh nghiệm về các công nghệ mới
                </li>
                <li>
                  Hợp tác R&D với các trường đại học trong và ngoài nước (Thụy
                  Điển, Úc, Mỹ, Na Uy…)
                </li>
                <li>Triển khai nhiều dự án lớn và phức tạp</li>
              </ul>
            </div>
            <div
              className="box-inno"
              style={{
                backgroundImage: `url(${box_innovation})`,
              }}
            >
              <h3 className="title-box-inno">Các trung tâm giải pháp</h3>
              <ul className="text-box-inno">
                <li>Ai center</li>
                <li>Healthtech center</li>
                <li>Smart device center</li>
              </ul>
            </div>
          </div>
          <div className="img-tma-innovation">
            <div className="circle-inno"></div>
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/logo-tma-innovation-01.webp")}
              alt="circle-tma-solution"
              className="logo-tma-inno"
            />
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/circle-innovation-01.webp")}
              alt="circle-tma-solution"
              className="circle-tma-inno"
            />
            <img
              src={require("../../../assets/image/solutions/solutionAccessControl/aboutUs/circle-innovation-mobile-01.webp")}
              alt="circle-tma-solution"
              className="circle-tma-innovation-mobile"
            />
            <h3 className="title-tma-inno">
              Đối tác công nghệ 4.0 tin cậy của bạn
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutUS;
