import React from "react";

const Footer = () => {
  let date = new Date().getFullYear();

  return (
    <footer className="d-flex align-items-center justify-content-center text-center text-md-left p-2 p-md-0">
      <p style={{ color: "#2C50C9" }}>
        © {date} TMA Innovation - All rights reserved
      </p>
    </footer>
  );
};

export default Footer;
