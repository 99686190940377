import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/sass/admin/gallaries.scss";

function TitleGallaries() {
  return (
    <section className="section" style={{ paddingTop: "120px" }}>
      <div className="container d-flex flex-column justify-content-center">
        <div className="flex mb-5">
          <div className="title-block mb-3">Hình ảnh</div>
          <span className="bg-line"></span>
        </div>
      </div>
    </section>
  );
}

export default TitleGallaries;
