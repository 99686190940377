import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/sass/admin/application.scss";
import "../../assets/sass/admin/solutions.scss";
import TitleSolution from "./TitleSolution";
import SmartRetail from "./SmartRetail";
import AccessControl from "./AccessControl";
import LaborSafety from "./LaborSafety";
import ParkingManagement from "./ParkingManagement";
import SmartBuilding from "./SmartBuilding";

function Solutions() {
  return (
    <section className="background-diff" id="solutions">
      <div className="icon-background-top">
        <img
          src={require("../../assets/image/solutions/icon-background-top.webp")}
          alt="img"
        />
      </div>
      <div className="icon-background-bettwen">
        <img
          src={require("../../assets/image/solutions/icon-background-bettwen.webp")}
          alt="img"
        />
      </div>
      <div className="icon-background-bettwen-down">
        <img
          src={require("../../assets/image/solutions/icon-background-bettwen2.webp")}
          alt="img"
        />
      </div>
      <div className="icon-background-bottom">
        <img
          src={require("../../assets/image/solutions/icon-background-bottom.webp")}
          alt="img"
        />
      </div>
      <div className="container">
        <div>
          <TitleSolution />
        </div>
        <div style={{ paddingBottom: "60px" }}>
          <SmartRetail />
        </div>
        <hr style={{ border: "1.5px dashed #279DD8" }} />
        <div style={{ paddingBottom: "60px" }}>
          <AccessControl />
        </div>
        <hr style={{ border: "1.5px dashed #279DD8" }} />
        <div style={{ paddingBottom: "60px" }}>
          <LaborSafety />
        </div>
        <hr style={{ border: "1.5px dashed #279DD8" }} />
        <div style={{ paddingBottom: "60px" }}>
          <ParkingManagement />
        </div>
        <hr style={{ border: "1.5px dashed #279DD8" }} />
        <div style={{ paddingBottom: "60px" }}>
          <SmartBuilding />
        </div>
      </div>
    </section>
  );
}

export default Solutions;
