import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/sass/admin/solutions.scss";
import ButtonSolution from "./button-solution";

function SmartBuilding() {
  return (
    <section className="section" style={{ paddingTop: "60px" }}>
      <div className="carousel">
        <div className="d-flex justify-content-between solutions">
          <div className="col-lg-5 solutions-image padding-left-0 padding-right-0">
            <div className="smart-building-meg">
              <div>
                Kiểm soát ra vào không chạm kết hợp hệ thống smart camera theo
                dõi an ninh, an toàn
              </div>
            </div>
            <div>
              <img
                src={require("../../assets/image/solutions/image-smart-building.webp")}
                width="100%"
                height="570px"
                alt="img"
              />
            </div>
          </div>
          <div className="col-lg-7 solutions-text padding-left-69">
            {" "}
            <p>Giải pháp Smart building</p>
            <ul className="a">
              <li>
                <span className="material-symbols-outlined icon_title">
                  {" "}
                  trip_origin{" "}
                </span>
                <div className="title_solution">
                  Tăng cường quản lý an ninh trong tòa nhà với các giải pháp
                  kiểm soát ra vào không chạm:
                </div>
                <ul className="b smart_ul">
                  <li className="padding-top-10 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      Nhận diện khuôn mặt, kích hoạt đóng mở cửa tự động, hỗ trợ
                      nhận diện QR-Code, thẻ từ NFC, Video call mở cửa từ xa.
                    </p>
                  </li>
                  <li className="li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>
                      Kiểm tra khẩu trang, thân nhiệt, thẻ xanh covid, hỗ trợ
                      kiểm tra thông tin dịch tễ.
                    </p>
                  </li>
                  <li className="li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      Nhận diện, chào đón khách hàng với hệ thống quản lý cuộc
                      hẹn.
                    </p>
                  </li>
                  <li className="li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Hỗ trợ chấm công nhân viên bằng khuôn mặt.</p>
                  </li>
                  <li className="li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Quản lý ra vào các khu vực dịch vụ, tiện ích,…</p>
                  </li>
                </ul>
              </li>
              <li className="padding-top-20">
                <span className="material-symbols-outlined icon_title">
                  {" "}
                  trip_origin{" "}
                </span>
                <div className="title_solution">
                  Kết hợp hệ thống Smart camera, phát hiện, cảnh báo các trường
                  hợp bất thường, nguy hiểm.
                </div>
                <ul className="b smart_ul ">
                  <li className="padding-top-10 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      Cảnh báo trẻ em, thú cưng ở các khu vực phòng gym, hồ bơi,
                      ban công.
                    </p>
                  </li>
                  <li className="li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>
                      Cảnh báo người trong khu vực nguy hiểm, khu dịch vụ - tiện
                      ích vào giờ giới nghiêm hoặc ngoài thời gian hoạt động.
                    </p>
                  </li>
                  <li className="li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Theo dõi, cảnh báo đám đông</p>
                  </li>
                  <li className="li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Theo dõi hoạt động của lực lượng an ninh, bảo vệ.</p>
                  </li>
                  <div className="d-flex justify-content-end padding-top-20">
                    <ButtonSolution />
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="icon-background-bottom-Smart-Building">
        <img
          src={require("../../assets/image/solutions/icon-background-bottom.webp")}
          alt="img"
        />
      </div>
    </section>
  );
}

export default SmartBuilding;
