import React from "react";
import "../../../assets/sass/solutions/accessControl/banner.scss";
import { Link } from "react-scroll";

const Banner = () => {
  return (
    <section id="banner" className="banner">
      <h2
        className="title-banner-1"
        data-aos="flip-right"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="3000"
      >
        Giải pháp kiểm soát ra vào khu vực kho quỹ ngân hàng
      </h2>
      <h3
        className="title-banner-2"
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="3000"
      >
        Giải pháp kiểm soát ra vào khu vực kho quỹ ngân hàng
      </h3>
      <div className="button-banner">
        <Link
          to="contact-us"
          data-to-scrollspy-id={"contact-us"}
          spy={false}
          smooth={true}
          hashSpy={false}
          duration={1500}
        >
          <div
            className="button-1"
            data-aos="fade-right"
            data-aos-offset="1000"
            data-aos-easing="ease-in-sine"
          >
            <p>Khám phá ngay</p>
          </div>
        </Link>
        <div
          className="button-2"
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
        >
          Xem video
        </div>
      </div>
    </section>
  );
};
export default Banner;
