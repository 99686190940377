import "../assets/sass/admin/solutionCore.scss";
export default function CoreSolutions() {
  return (
    <section id="solution">
      <div
        className="section container padding-left-right-0"
        style={{
          paddingTop: "120px",
          paddingBottom: "120px",
          paddingLeft: "0",
          paddingRight: "0px",
        }}
      >
        <div className="d-flex icon-hr">
          <div className="solution-core-dots-nav">
            <div className="solution-core-dots" />
          </div>
          <div>
            <hr className="olution-core-hr" />
          </div>
          <div className="olution-core-hr-one-nav">
            <hr className="olution-core-hr-one" />
          </div>
        </div>
        <div className="d-flex icon-hr3">
          <div className="solution-core-dots-nav">
            <div className="solution-core-dots" />
          </div>
          <div>
            <hr className="olution-core-hr" />
          </div>
        </div>
        <div className="d-flex icon-hr2">
          <div className="solution-core-dots-nav">
            <div className="solution-core-dots" />
          </div>
          <div>
            <hr className="olution-core-hr" />
          </div>
          <div className="olution-core-hr-one-nav">
            <hr className="olution-core-hr-one" />
          </div>
        </div>
        <div className="d-flex icon-hr4">
          <div className="olution-core-hr-one-nav">
            <hr className="olution-core-hr-one" />
          </div>
          <div>
            <hr className="olution-core-hr" />
          </div>
          <div className="solution-core-dots-nav">
            <div className="solution-core-dots" />
          </div>
        </div>
        <div className="d-flex icon-hr5">
          <div className="olution-core-hr-one-nav">
            <hr className="olution-core-hr-one" />
          </div>
          <div>
            <hr className="olution-core-hr" />
          </div>
          <div className="solution-core-dots-nav">
            <div className="solution-core-dots" />
          </div>
        </div>
        <div className="d-flex icon-hr6">
          <div className="olution-core-hr-one-nav">
            <hr className="olution-core-hr-one" />
          </div>
        </div>
        <div
          className="flex"
          style={{ paddingBottom: "60px", marginTop: "-200px" }}
        >
          <div className="title-block mb-1">Giải pháp cốt lõi</div>
          <span className="bg-line"></span>
        </div>
        <div className="row justify-content-center">
          <div
            className="col-4 flex"
            style={{ paddingRight: "40px", paddingLeft: "35px" }}
          >
            <div className="d-flex justify-content-end full-width">
              <div className="icon-image text-center mr-3">
                <img
                  src={require("../assets/image/icons/face-icon.webp")}
                  alt="logo"
                  height="46"
                />
              </div>
              <button className="button-solution mr-2">
                <p>Nhận diện khuôn mặt</p>
              </button>
            </div>
            <div className="d-flex justify-content-end align-items-center full-width padding-top-20">
              <div className="icon-image text-center mr-3">
                <img
                  src={require("../assets/image/icons/person-icon.webp")}
                  height="46"
                  alt="logo"
                />
              </div>
              <button className="button-solution mr-2">
                <p>Nhận diện người</p>
              </button>
            </div>
            <div className="d-flex justify-content-end align-items-center full-width padding-top-30">
              <div className="icon-image text-center mr-3">
                <span className="bg-hand_icon"></span>
              </div>
              <button className="button-solution mr-2">
                <p>Nhận diện vật thể</p>
              </button>
            </div>
            <div className="d-flex justify-content-end align-items-center full-width padding-top-20">
              <div className="icon-image text-center mr-3">
                <img
                  src={require("../assets/image/icons/behavior-icon.webp")}
                  alt="logo"
                  height="46"
                />
              </div>
              <button className="button-solution mr-2">
                <p>Phân tích hành vi</p>
              </button>
            </div>
            <div className="d-flex justify-content-end align-items-center full-width padding-top-30">
              <div className="icon-image text-center mr-3">
                <span className="bg-follow_active_icon"></span>
              </div>
              <button className="button-solution mr-2">
                <p>Theo dõi hoạt động</p>
              </button>
            </div>
            <div className="d-flex justify-content-end align-items-center full-width padding-top-20">
              <div className="icon-image text-center mr-3">
                <img
                  src={require("../assets/image/icons/icon-process-analysis.webp")}
                  alt="logo"
                  height="46"
                />
              </div>
              <button className="button-solution mr-2">
                <p>Phân tích quy trình</p>
              </button>
            </div>
          </div>
          <div className="col-4 flex pr-0 pl-0">
            <div className="text-center border-camera flex">
              <img
                src={require("../assets/image/icon-t-cam-new.webp")}
                className="icon-t-cam-new"
                alt="camera-ip"
              />
            </div>
          </div>
          <div
            className="col-4 flex"
            style={{ paddingLeft: "40px", paddingRight: "35px" }}
          >
            <div className="d-flex justify-content-start align-items-center full-width">
              <button className="button-solution ml-2">
                <p className="mt-2 mb-2">Phân tích thông minh</p>
              </button>
              <div className="icon-image text-center ml-3">
                <img
                  src={require("../assets/image/icons/icon-Intelligent-analysis.webp")}
                  height="46"
                  alt="logo"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center full-width padding-top-20">
              <button className="button-solution ml-2">
                <p>Kiểm soát thông minh</p>
              </button>
              <div className="icon-image text-center ml-3">
                <img
                  src={require("../assets/image/icons/people-icon.webp")}
                  alt="logo"
                  height="46"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center full-width padding-top-30">
              <button className="button-solution ml-2">
                <p>Quản lý thông minh</p>
              </button>
              <div className="icon-image text-center ml-3">
                <img
                  src={require("../assets/image/icons/smart-manage.webp")}
                  alt="logo"
                  height="46"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center full-width padding-top-30">
              <button className="button-solution ml-2">
                <p>Sản xuất thông minh</p>
              </button>
              <div className="icon-image text-center ml-3">
                <img
                  src={require("../assets/image/icons/icon-smart-production.webp")}
                  height="46"
                  alt="logo"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center full-width padding-top-20">
              <button className="button-solution ml-2">
                <p>Dịch vụ thông minh</p>
              </button>
              <div className="icon-image text-center ml-3">
                <img
                  src={require("../assets/image/icons/icon-smart-service.webp")}
                  height="46"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center"
          style={{ paddingTop: "120px" }}
        >
          <div className="col-3 col-box-solution-top-one col-box margin-right-24">
            <div className="box-solution d-flex flex-column align-items-center">
              <img
                className="mt-5 mb-1"
                src={require("../assets/image/liveness-detection.webp")}
                alt="camera-ip"
                width="67"
              />
              <p className="padding-top-28 padding-bottom-36">
                Phân biệt người và hình ảnh - Chống giả mạo khuôn mặt Liveness
                detection
              </p>
            </div>
          </div>
          <div className="col-3 col-box-solution-top-two margin-left-24 margin-right-24">
            <div className="box-solution d-flex flex-column align-items-center">
              <img
                className="mt-5 mb-1"
                src={require("../assets/image/function.webp")}
                alt="camera-ip"
                width="67"
              />
              <p className="padding-top-28 ">
                Linh hoạt, tuỳ biến chức năng theo nhu cầu
              </p>
            </div>
          </div>
          <div className="col-3 col-box-solution-top-three margin-left-24 margin-right-24">
            <div className="box-solution d-flex flex-column align-items-center">
              <img
                className="mt-5 mb-1"
                src={require("../assets/image/analysis.webp")}
                alt="camera-ip"
                width="67"
              />
              <p className="padding-top-28 padding-bottom-36">
                Nhanh chóng phân tích, phát hiện, cảnh báo với độ chính xác cao
              </p>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center"
          style={{ paddingTop: "53px" }}
        >
          <div className="col-3 margin-right-24 col-box-solution-bottom-one">
            <div className="box-solution d-flex flex-column align-items-center">
              <img
                className="mt-5 mb-1"
                src={require("../assets/image/control.webp")}
                alt="camera-ip"
                width="67"
              />
              <p className="padding-top-28 ">
                Toàn quyền kiểm soát dữ liệu, an toàn và bảo mật
              </p>
            </div>
          </div>
          <div className="col-3 margin-left-24 margin-right-24 col-box-solution-bottom-two">
            <div className="box-solution d-flex flex-column align-items-center">
              <img
                className="mt-5 mb-1"
                src={require("../assets/image/intergrated.webp")}
                alt="camera-ip"
                width="67"
              />
              <p className="padding-top-28 ">
                Không ảnh hưởng đến các hệ thống khác
              </p>
            </div>
          </div>
          <div className="col-3 margin-left-24 margin-right-24 col-box-solution-bottom-three">
            <div className="box-solution d-flex flex-column align-items-center">
              <img
                className="mt-5 mb-1"
                src={require("../assets/image/icon-ai-device.webp")}
                alt="camera-ip"
                width="67"
              />
              <p className="padding-top-28 ">
                Tích hợp AI trên nhiều loại thiết bị khác nhau
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
