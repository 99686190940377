import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/sass/admin/solutions.scss";
import ButtonSolution from "./button-solution";

function ParkingManagement() {
  return (
    <section className="section" style={{ paddingTop: "60px" }}>
      <div className="carousel">
        <div className="d-flex justify-content-between solutions">
          <div className="col-7 solutions-text ">
            {" "}
            <p>Giải pháp hỗ trợ quản lý bãi xe</p>
            <ul className="p-0">
              <li>
                <ul className="b parking_manager">
                  <li className=" li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>
                      {" "}
                      Đếm lượt xe ra vào các khu vực bãi xe, bãi khai thác, nhà
                      máy, nhà kho
                    </p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>
                    <p>Phát hiện và cảnh báo xe đi sai hướng</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Nhận diện biển số</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Nhận diện số hiệu trên xe</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p> Nhận diện chỗ trống</p>
                  </li>
                  <li className="padding-top-5 li_father">
                    {" "}
                    <span className="material-symbols-outlined arrow_right">
                      arrow_right
                    </span>{" "}
                    <p>Cảnh báo xe đỗ sai vị trí, khu vực ưu tiên</p>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="d-flex padding-top-20">
              <ButtonSolution />
            </div>
          </div>
          <div className="col-5">
            <div>
              <img
                src={require("../../assets/image/solutions/image10.webp")}
                width="100%"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ParkingManagement;
